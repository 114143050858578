﻿body
{
	padding-bottom: 1rem;
}


.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
	float: none !important;
}


ul.select, ul.sk-select {
	list-style: none;
	display: block;
	margin: 0;
	padding: 0;
}

ul.sk-select.flex > li > * {
	display: flex;
}

ul.sk-select > li {
	display: block;
	padding: 0.6rem 0.9rem;
	cursor: pointer;
	width: auto;
	border-bottom: 1px solid hsl(0, 0%, 80%);
}

ul.sk-select > li:last-child
{
	border-bottom: none;
}

ul.sk-select > li > a {
	text-decoration: none !important;
}

section.input {
	clear: both;
}



ul.sk-select > li a {
	display: block;
}

ul.sk-select > li .row {
	margin: 0;
	padding: 0;
}

ul.sk-select > li > * {
	display: block;
	margin: -0.5rem -0.3rem;
	padding: 1rem 0.3rem;
	color: inherit;	
}

ul.sk-select > li > * > * {

}

ul.sk-select > li:hover {
	background-color: hsl(0, 0%, 92%);
}

ul.sk-select > li.selected, ul.sk-select > li.active {
	background-color: hsl(0, 0%, 90%);
}

ul.sk-select > li.selected.danger, ul.sk-select > li.active.danger {
	background-color: hsl(0, 68%, 42%);
}

ul.sk-select > li.primary {
	background-color: hsl(210, 50%, 45%);
	color: white;
}

ul.sk-select > li.primary:hover {
	background-color: hsl(210, 50%, 40%);
}


.sk-title-bar {
	display: block;
	margin: 0;
	padding: 1rem;
	display: flex;
}

.sk-title-bar.primary {
	background-color: #025aa5;
	color: white;
}

.sk-title-bar > .center {
	display: block;
	float: none;
	margin-left: auto;
	margin-right: auto;
	text-align:center;
	flex: 1 100%;
	order: 2;
}

.sk-title-bar > .left {
	display: block;
	flex: 1 100%;
	order: 1;
}

.sk-title-bar > .right {
	display: block;
	flex: 1 100%;
	order: 3;
}


.sk-margin-top {
	margin-top: 1rem;
}

.sk-margin-top.big {
	margin-top: 3rem;
}

.sk-margin-bottom {
	margin-bottom: 1rem;
}

.sk-margin-bottom.big {
	margin-bottom: 3rem;
}

.ngdialog-content {
	padding: 1rem;
}







.switch {
  border: none;
  margin-bottom: 1.5rem;
  outline: 0;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .switch label {
    background: #DDDDDD;
    color: transparent;
    cursor: pointer;
    display: block;
    margin-bottom: 1rem;
    position: relative;
    text-indent: 100%;
    width: 4rem;
    height: 2rem;
    transition: left 0.15s ease-out; }
  .switch input {
    left: 10px;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 9px; }
    .switch input + label {
      margin-left: 0;
      margin-right: 0; }
  .switch label:after {
    background: #FFFFFF;
    content: "";
    display: block;
    height: 1.5rem;
    left: .25rem;
    position: absolute;
    top: .25rem;
    width: 1.5rem;
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: translate3d(0, 0, 0);
    transition: left 0.15s ease-out;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .switch input:checked + label {
    background: #326699; }
  .switch input:checked + label:after {
    left: 2.25rem; }
  .switch label {
    height: 2rem;
    width: 4rem; }
  .switch label:after {
    height: 1.5rem;
    width: 1.5rem; }
  .switch input:checked + label:after {
    left: 2.25rem; }
  .switch label {
    color: transparent;
    background: #DDDDDD; }
  .switch label:after {
    background: #FFFFFF; }
  .switch input:checked + label {
    background: #326699; }
  .switch.large label {
    height: 2.5rem;
    width: 5rem; }
  .switch.large label:after {
    height: 2rem;
    width: 2rem; }
  .switch.large input:checked + label:after {
    left: 2.75rem; }
  .switch.small label {
    height: 1.75rem;
    width: 3.5rem; }
  .switch.small label:after {
    height: 1.25rem;
    width: 1.25rem; }
  .switch.small input:checked + label:after {
    left: 2rem; }
  .switch.tiny label {
    height: 1.5rem;
    width: 3rem; }
  .switch.tiny label:after {
    height: 1rem;
    width: 1rem; }
  .switch.tiny input:checked + label:after {
    left: 1.75rem; }
  .switch.radius label {
    border-radius: 4px; }
  .switch.radius label:after {
    border-radius: 3px; }
  .switch.round {
    border-radius: 1000px; }
    .switch.round label {
      border-radius: 2rem; }
    .switch.round label:after {
      border-radius: 2rem; }
















    body {
	color: hsl(0, 0%, 13%);
}

html,body {
	height: 100%;
}

.text-builder {
	
}

.text-builder {
	
	
}

.text-builder.active {
	/*background-color: hsl(0, 0%, 97%);*/
	
}

.text-builder  .item {
	margin-right: 1rem;
	margin-bottom: 1rem;
	padding: 0.4rem;
	border: 1px solid hsl(0, 0%, 70%);
	background-color: hsl(0, 0%, 97%);
	display: inline-block;
	border-radius: 0.2rem;
	cursor: pointer;
	min-width: 2rem;
}

.text-builder .item:hover {
	background-color: hsl(0, 0%, 80%);
}

.text-builder .item:active {
	background-color: hsl(0, 0%, 70%);
}

.text-builder textarea {
	width: 100%;
	min-height: 2rem;
}

.text-builder .output-area, .text-builder .block-area {
	margin-bottom: 1rem;
}

.main-view-container {
	
	/*margin-top: 4rem;*/
}

section.input {
	
	margin-bottom: 5rem;
}

section.input > div {
	padding: 0 1rem 1rem 1rem;
}

section.input h4 {
	/*background-color: hsl(210, 50%, 40%);*/
	font-weight: lighter;
	font-size: 1.4rem;
	padding: 0.5rem 1rem;
	margin: 0;
	margin-bottom: 2rem;
	border-bottom: 1px solid hsl(0, 0%, 40%);
}



#projectBase {
	margin: 0;
	height: 100%;
}

#projectBase > li {

}

#projectBase .menu-area {
	background-color: hsl(0, 0%, 40%);
	color: white;
	padding: 0;
	height: 100%;
	min-height: 100%;
	display: block;
	margin-left: -15px;
}

#projectBase .menu-area ul li a {
	color: white;
	width: 100%;
	height: 100%;
	display: block;
	padding: 0.7rem 1rem 0.7rem 2rem;
	text-decoration: none !important;
}

#projectBase .menu-area ul li a:hover {
	background-color: hsl(0, 0%, 60%);
}

#projectBase .menu-area ul li.active a {
	background-color: hsl(210, 50%, 40%);
}

#projectBase .content-area {
	padding-top: 1.8rem;
}







ul.action, ul.sk-action {
	list-style: none;
	display: block;
	margin: 0;
	background-color: hsl(0, 0%, 55%);
	color: hsl(0, 0%, 100%);
}

ul.action > li, ul.sk-action > li {
	display: inline-block;
	padding: 0.5rem 0.7rem;
	cursor: pointer;
	width: auto;

}

ul.action > li:hover, ul.sk-action > li:hover {
	//background-color: hsl(0, 0%, 60%);
	text-decoration: underline;
}

ul.action > li:last-child, ul.sk-action > li:last-child {

}

ul.action > li > a, ul.sk-action > li > a {
	color: inherit;
	text-decoration: none !important;
}



.btn.btn-primary {
	background-color: hsl(210, 50%, 60%);
	color: white;
}

.btn.btn-primary:hover {
	background-color: hsl(210, 50%, 50%);
}

.primary.background {
	background-color: hsl(210, 50%, 50%);
	color: white;
}

.sk-container {
	margin: 1rem;
}


.sk-space-bottom {
	margin-bottom: 2rem;
}

.sk-space-bottom.big {
	margin-bottom: 4rem;
}

.sk-highlight {
	font-weight: bold;
}









.modal-backdrop {
	background-color: hsla(0, 0%, 0%, 0.5);
}

.modal-backdrop.fade, .modal-backdrop.in {
	opacity: 1;
}

.modal {
	display: block;
	overflow-y: auto;
}

.modal-content {
	
}



@media (min-width: 992px) {
	.sticky-on-large-screens {
		position: fixed;
	}

	.modal-dialog.is-large {
		width: 90% !important;
	}
}